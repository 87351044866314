import { VendorType } from "api-hooks/vendor/vendor.model";
import invariant from "invariant";
import { authSelector } from "models/auth";
import * as React from "react";
import { useSelector } from "react-redux";

export interface AccountProps {
  id: string | undefined;
  name: string | undefined;
  username: string | undefined;
  imageUrl: string | undefined;
  isShop: boolean | undefined;
  shopRole: string | undefined;
  isVendor: boolean | undefined;
  type: string | undefined;
  vendorType: VendorType | null | undefined;
}

interface ContextProps {
  account: AccountProps;
  setAccount: (load: AccountProps) => void;
}

const accountContext = React.createContext<ContextProps>({
  account: {
    id: undefined,
    name: undefined,
    username: undefined,
    imageUrl: undefined,
    isShop: undefined,
    shopRole: undefined,
    isVendor: undefined,
    type: undefined,
    vendorType: undefined,
  },
  setAccount: () => {},
});

interface AccountProviderProps {
  children: React.ReactNode;
}

export function AccountProvider(props: AccountProviderProps) {
  const me = useSelector(authSelector.userSelector);
  const accountRedux = useSelector(authSelector.account);

  const initialAccount = React.useMemo(() => {
    const personal = {
      id: me?.id,
      name: me?.name,
      username: undefined,
      imageUrl: me?.imageUrl,
      isShop: false,
      shopRole: undefined,
      isVendor: false,
      type: undefined,
      vendorType: null,
    };
    const businesss = me?.shops?.find((item) => item.id === accountRedux?.id!);
    if (!!accountRedux && !accountRedux.isShop) {
      return personal;
    } else if (!!accountRedux && !!businesss) {
      return {
        id: businesss?.id,
        name: businesss?.name,
        username: businesss?.username,
        imageUrl: businesss?.imageUrl,
        isShop: true,
        shopRole: businesss?.role,
        isVendor: businesss?.isVendor,
        type: businesss?.type,
        vendorType: businesss?.vendorType,
      } as AccountProps;
    } else {
      if (me?.accountDefault === null) {
        return personal;
      } else {
        const shopAccount = me?.shops?.find(
          (shop) => shop?.id === me?.accountDefault
        );
        return {
          id: shopAccount?.id,
          name: shopAccount?.name,
          username: shopAccount?.username,
          imageUrl: shopAccount?.imageUrl,
          isShop: true,
          shopRole: shopAccount?.role,
          isVendor: shopAccount?.isVendor,
          type: shopAccount?.type,
          vendorType: shopAccount?.vendorType,
        };
      }
    }
  }, [
    accountRedux,
    me?.accountDefault,
    me?.id,
    me?.imageUrl,
    me?.name,
    me?.shops,
  ]);
  const [account, setAccount] = React.useState<AccountProps>(initialAccount);
  React.useEffect(() => {
    if (!account?.id && !!me) {
      setAccount(initialAccount);
    }
  }, [account?.id, initialAccount, me]);
  return (
    <accountContext.Provider value={{ account, setAccount }}>
      {props.children}
    </accountContext.Provider>
  );
}

export function useAccount() {
  const context = React.useContext(accountContext);
  invariant(
    context !== undefined,
    "useAccount must be used inside accountContext.Provider"
  );
  return context;
}
