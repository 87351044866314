import { createModel } from "@rematch/core";
import {
  Credentials,
  // OtpRequestTimerPayload,
  User,
} from "api-hooks/auth/auth.model";
import { getMe } from "api-hooks/auth/auth.query";
import CryptoJs from "crypto-js";
import { AccountProps } from "hooks/use-account";
import { produce } from "immer";
import { RematchDispatch, RootState } from "models";
import { Dispatch } from "redux";
export interface AuthState {
  data?: User;
  account?: AccountProps;
  authenticationError?: string;
  postponeUpdateAppUntilTimestamp?: number;
  credentials?: Credentials;
  otpRequestTimer?: object;
  lastOpenedAccountId: string;
}

const initialState: AuthState = {
  data: undefined,
  authenticationError: undefined,
  postponeUpdateAppUntilTimestamp: undefined,
  account: undefined,
  credentials: undefined,
  otpRequestTimer: {},
  lastOpenedAccountId: "",
};

const secretKey = "veryinterestingsecret";

const auth = createModel()({
  name: "auth",
  state: initialState,
  reducers: {
    setUser(state: AuthState, payload: User): AuthState {
      return produce(state, (draft) => {
        draft.data = payload;
      });
    },
    setAuthenticationError(state: AuthState, payload: string): AuthState {
      return produce(state, (draft) => {
        draft.authenticationError = payload;
      });
    },
    setPostponeUpdateAppUntilTimestamp(
      state: AuthState,
      payload: number
    ): AuthState {
      return produce(state, (draft) => {
        draft.postponeUpdateAppUntilTimestamp = payload;
      });
    },
    setAccount(state: AuthState, payload: AccountProps) {
      return produce(state, (draft) => {
        draft.account = payload;
      });
    },

    setCredentials(state: AuthState, payload: Credentials) {
      return produce(state, (draft) => {
        draft.credentials = {
          ...payload,
          accessToken: CryptoJs.AES.encrypt(
            payload.accessToken,
            secretKey
          ).toString(),
          refreshToken: CryptoJs.AES.encrypt(
            payload.refreshToken,
            secretKey
          ).toString(),
        };
      });
    },
    reset(): AuthState {
      return {
        ...initialState,
      };
    },
    setLastOpenedAccountId(state: AuthState, payload: string): AuthState {
      return produce(state, (draft) => {
        draft.lastOpenedAccountId = payload;
      });
    },
  },
  effects: {
    async getMe(): Promise<User | undefined> {
      const user = await getMe();
      this.setUser(user);
      return user;
    },
  },
});

// Selector
const userSelector = (state: RootState) => state.auth.data;
const authErrorSelector = (state: RootState) => state.auth.authenticationError;
const postponeUpdateAppUntilTimestampSelector = (state: RootState) =>
  state.auth.postponeUpdateAppUntilTimestamp;
const otpRequestTimer = (state: RootState) => state.auth.otpRequestTimer;

const account = (state: RootState) => state.auth.account;
const credentials = (state: RootState) => {
  if (state.auth.credentials) {
    return {
      ...state.auth.credentials,
      accessToken: CryptoJs.AES.decrypt(
        state.auth.credentials.accessToken,
        secretKey
      ).toString(CryptoJs.enc.Utf8),
      refreshToken: CryptoJs.AES.decrypt(
        state.auth.credentials.refreshToken,
        secretKey
      ).toString(CryptoJs.enc.Utf8),
    };
  }
  return null;
};
export const authSelector = {
  userSelector,
  authErrorSelector,
  postponeUpdateAppUntilTimestampSelector,
  account,
  credentials,
  otpRequestTimer,
};

// Dispatcher
export const authDispatcher = (dispatch: Dispatch) => {
  const authDispatch = dispatch as RematchDispatch;
  return {
    reset: () => {
      authDispatch.auth.reset();
    },
    getMe: async (): Promise<User | undefined> => {
      return await authDispatch.auth.getMe();
    },
    setAuthError: (payload: string) => {
      return authDispatch.auth.setAuthenticationError(payload);
    },
    setPostponeUpdateAppUntilTimestamp: (payload: number) => {
      return authDispatch.auth.setPostponeUpdateAppUntilTimestamp(payload);
    },
    setAccount: (payload: AccountProps) => {
      return authDispatch.auth.setAccount(payload);
    },
    setCredentials: (payload: Credentials) => {
      return authDispatch.auth.setCredentials(payload);
    },
    setLastOpenedAccountId: (payload: string) => {
      return authDispatch.auth.setLastOpenedAccountId(payload);
    },
  };
};

export default auth;
