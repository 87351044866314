export default {
  services: "Servis",
  energy: "Energy",
  bbm: "BBM",
  service_setting: "Edit Kolom",
  logbook: "Logbook",
  multiple_service_limit: "Hanya bisa pilih maksimal {{limit}} aset",
  add_memo: "Add memo...",
  memo_placeholder:
    "Memo tidak bisa dilihat oleh vendor,\nhanya bisa dilihat oleh anda dan tim.",
  vendor_memo_placeholder:
    "Memo tidak bisa dilihat oleh customer,\nhanya bisa dilihat oleh anda dan tim.",
  personal_memo_placeholder: "Ketik memo di sini",
  delete_memo: "Hapus Memo",
  delete_memo_message: "Memo yang dihapus tidak bisa dipulihkan kembali.",
  service_detail: "Detail Servis",
  choose_template: "Pilih Template",
  multiple_service_exit_confirmation:
    "Data yang sudah diisi akan hilang dan tidak bisa dipulihkan kembali.",
  delete_template: "Hapus Template",
  delete_template_confirmation:
    "Apakah anda yakin akan ingin menghapus template ini?",
  template_name_placeholder: "Isi nama template",
  add_template: "Add Template",
  edit_template: "Edit Template",
  note_template: "Catat {{total}} {{item_name}}",
  template_name: "Nama Template",
  multiple_service_out_confirmation:
    "Data yang sudah diisi akan hilang dan tidak bisa dipulihkan kembali.",
  request_service: "Request Servis",
  notify_team: "Notifikasi Team",
  whole_team: "Semua Team",
  response_request: "Respon Request",
  service_request_confirmation:
    "Apakah servis ini untuk merespon request servis oleh {{name}}?",
  response_request_confirmation:
    "Kamu akan merespon request servis oleh {{name}}?",
  have_follow_up: "Sudah Follow Up",
  have_not_follow_up: "Belum Follow Up",
  maximum_file_size: "Ukuran File Maksimal 2 MB",

  not_supported_format: "{{format}} Jenis Format ini Tidak didukung Sistem",
  upload_attachment_failed: "Upload Attachment Gagal",
};
